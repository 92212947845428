import type { ThemeOptions } from "@mui/material/styles";

import { spacing, theme, tokens } from "../tokens";
import { typographyVariants } from "./typography";

export const dialog: ThemeOptions = {
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: spacing(1),
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          ...typographyVariants["h7"],
          textAlign: "center",
          color: tokens.palette.neutral[7],
          paddingLeft: spacing(6),
          paddingRight: spacing(6),
          paddingTop: spacing(3),
          paddingBottom: spacing(3),
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          ...typographyVariants["b5"],
          color: tokens.palette.neutral[7],

          ".MuiDialog-alert &": {
            textAlign: "center",
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: "center",
          paddingTop: spacing(3),
          paddingBottom: spacing(3),
          paddingLeft: spacing(3),
          paddingRight: spacing(3),
          gap: spacing(2),
          "& :not(style) ~ :not(style)": {
            marginLeft: spacing(0),
          },
          flexDirection: "column-reverse",
          [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
            paddingLeft: spacing(4),
            paddingRight: spacing(4),
            paddingBottom: spacing(4),
          },
          ".MuiDrawer-root &": {
            paddingBottom: spacing(7),
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          ".MuiDialog-normal &": {
            paddingLeft: spacing(3),
            paddingRight: spacing(3),
            paddingBottom: spacing(0),
            [theme.breakpoints.up("sm")]: {
              paddingLeft: spacing(4),
              paddingRight: spacing(4),
            },
            "&:first-of-type": {
              paddingTop: spacing(3),
            },
            "&:last-of-type": {
              paddingBottom: spacing(3),
              [theme.breakpoints.up("sm")]: {
                paddingBottom: spacing(4),
              },
            },
            // Reset Icon, Title and Actions styles to allow use inside MuiContent
            // Use inside MuiContent will let them scroll with the content but not fixed
            "& .MuiDialogIcon-root": {
              paddingTop: spacing(2),
              paddingBottom: spacing(2),
            },
            "& .MuiDialogTitle-root": {
              padding: 0,
            },
            "& .MuiDialogActions-root": {
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: spacing(3),
              paddingBottom: spacing(4),
              [theme.breakpoints.up("sm")]: {
                paddingBottom: spacing(1),
              },
            },
          },
        },
      },
    },
  },
};
