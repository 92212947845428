import type { ThemeOptions } from "@mui/material/styles";

import { tokens } from "../tokens";

export const paper: ThemeOptions = {
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          color: tokens.palette.neutral[7],
        },
        elevation1: {
          boxShadow: "0px 4px 8px 0px rgba(52, 87, 140, 0.12)",
        },
      },
    },
  },
};
