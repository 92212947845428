import type { ThemeOptions } from "@mui/material/styles";

import { tokens } from "../tokens";

export const skeleton: ThemeOptions = {
  components: {
    MuiSkeleton: {
      defaultProps: {
        animation: "wave",
      },
      styleOverrides: {
        root: {
          backgroundColor: `${tokens.palette.neutral[3]}50`,
        },
      },
    },
  },
};
