import type { ThemeOptions } from "@mui/material/styles";

export const card: ThemeOptions = {
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
};
