import type { ThemeOptions } from "@mui/material/styles";

import { tokens } from "../tokens";

export const cssBaseline: ThemeOptions = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          background: tokens.palette.neutral[1],
        },
        "*": {
          boxSizing: "border-box",
        },
      },
    },
  },
};
