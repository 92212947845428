import type { ThemeOptions } from "@mui/material/styles";
import { IconSquare, IconSquareCheckFilled } from "@tabler/icons-react";

import { tokens } from "../tokens";

export const checkbox: ThemeOptions = {
  components: {
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
        icon: <IconSquare />,
        checkedIcon: <IconSquareCheckFilled />,
      },
      styleOverrides: {
        root: {
          padding: 0,
          color: tokens.palette.neutral[3],
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          gap: "8px",
          marginLeft: 0,
          marginRight: 0,
          alignItems: "flex-start",
        },
        label: {
          fontSize: "16px",
          lineHeight: "24px",
          color: tokens.palette.neutral[7],
        },
      },
    },
  },
};
