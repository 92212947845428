type Shadows = {
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
};

export type PaletteShadows = {
  primary: Shadows;
  light: Shadows;
  dark: Shadows;
};

// Because the default MUI shadows can only be an array with 25 items, so we need to implement a new palette named `shadows`
// Then use it in component like this:
// <Box sx={{ boxShadow: (theme) => theme.palette.shadows.primary[100]" }} />

export const shadows: PaletteShadows = {
  primary: {
    100: "0px 1px 1px rgba(38, 103, 255, 0.10), 0px 0px 1px rgba(38, 103, 255, 0.05)",
    200: "0px 2px 4px rgba(38, 103, 255, 0.10), 0px 0px 1px rgba(38, 103, 255, 0.05)",
    300: "0px 4px 8px rgba(38, 103, 255, 0.10), 0px 0px 1px rgba(38, 103, 255, 0.05)",
    400: "0px 8px 16px rgba(38, 103, 255, 0.10), 0px 0px 1px rgba(38, 103, 255, 0.05)",
    500: "0px 16px 24px rgba(38, 103, 255, 0.10), 0px 0px 1px rgba(38, 103, 255, 0.05)",
    600: "0px 24px 40px rgba(38, 103, 255, 0.10), 0px 0px 1px rgba(38, 103, 255, 0.05)",
  },
  light: {
    100: "0px 1px 1px rgba(48, 49, 51, 0.10), 0px 0px 1px rgba(48, 49, 51, 0.05)",
    200: "0px 2px 4px rgba(48, 49, 51, 0.10), 0px 0px 1px rgba(48, 49, 51, 0.05)",
    300: "0px 4px 8px rgba(48, 49, 51, 0.10), 0px 0px 1px rgba(48, 49, 51, 0.05)",
    400: "0px 8px 16px rgba(48, 49, 51, 0.10), 0px 0px 1px rgba(48, 49, 51, 0.05)",
    500: "0px 16px 24px rgba(48, 49, 51, 0.10), 0px 0px 1px rgba(48, 49, 51, 0.05)",
    600: "0px 24px 40px rgba(48, 49, 51, 0.10), 0px 0px 1px rgba(48, 49, 51, 0.05)",
  },
  dark: {
    100: "0px 1px 1px #000, 0px 0px 1px rgba(0, 0, 0, 0.90)",
    200: "0px 2px 4px #000, 0px 0px 1px rgba(0, 0, 0, 0.90)",
    300: "0px 4px 8px #000, 0px 0px 1px rgba(0, 0, 0, 0.90)",
    400: "0px 8px 16px #000, 0px 0px 1px rgba(0, 0, 0, 0.90)",
    500: "0px 16px 24px #000, 0px 0px 1px rgba(0, 0, 0, 0.90)",
    600: "0px 24px 40px #000, 0px 0px 1px rgba(0, 0, 0, 0.90)",
  },
};
