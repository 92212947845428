import type { ThemeOptions } from "@mui/material";

import { tokens } from "../tokens";
import { typographyVariants } from "./typography";

export const pagination: ThemeOptions = {
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          height: 36,
          minWidth: 36,
          ...typographyVariants.sh5,
          color: tokens.palette.neutral[5],
          "&.Mui-selected": {
            ...typographyVariants.h5,
            color: tokens.palette.neutral[7],
          },
        },
        rounded: {
          borderRadius: 10,
          backgroundColor: tokens.palette.neutral[1],
          border: `1px solid transparent`,
          "&.Mui-selected": {
            border: `1px solid ${tokens.palette.neutral[2]}`,
            backgroundColor: tokens.palette.neutral[0],
            "&:hover": {
              backgroundColor: tokens.palette.neutral[0],
            },
          },
          "&.MuiPaginationItem-previousNext, &.MuiPaginationItem-firstLast": {
            color: tokens.palette.neutral[7],
            backgroundColor: "transparent",
          },
          "&:hover": {
            color: tokens.palette.primary[4],
          },
          "@media (hover: none)": {
            "&:hover": {
              color: tokens.palette.neutral[5],
            },
          },
          "&.MuiPaginationItem-ellipsis": {
            color: tokens.palette.neutral[5],
            backgroundColor: tokens.palette.neutral[1],
            border: `1px solid transparent`,
          },
        },
      },
    },
  },
};
