import type { ThemeOptions } from "@mui/material/styles";

import { typographyVariants } from "./typography";

export const chip: ThemeOptions = {
  components: {
    MuiChip: {
      variants: [
        {
          props: { size: "large" },
          style: {
            ...typographyVariants.sh5,
            padding: "10px 12px",
            height: "44px",
            borderRadius: "44px",
          },
        },
      ],
    },
  },
};

declare module "@mui/material/Chip" {
  interface ChipPropsSizeOverrides {
    large: true;
  }
}
