import type { ThemeOptions } from "@mui/material";

import { tokens } from "../tokens";

export const menu: ThemeOptions = {
  components: {
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: 4,
        },
        paper: {
          borderRadius: 6,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          padding: "8px 16px",
          backgroundColor: "transparent",
          ":hover": {
            backgroundColor: tokens.palette.neutral[2],
          },
          "&.Mui-selected": {
            backgroundColor: "transparent",
            color: tokens.palette.primary[4],
            "& .MuiListItemIcon-root": {
              color: tokens.palette.primary[4],
            },
            "& .MuiListItemText-primary": {
              fontWeight: 600,
            },
          },
        },
      },
    },
  },
};
