import { createTheme } from "@mui/material/styles";

import { tokens } from "./v2/tokens";

export const theme = createTheme({
  // @ts-expect-error -- Ignore because conflict types with new version
  typography: {
    fontFamily: ["Nunito", "Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
  },

  // @ts-expect-error
  palette: {
    primary: {
      main: "#02bff2",
      dark: "#0185a9",
      light: "#34cbf4",
      extraLight: "#def4fd",
      contrastText: "#fff",
    },
    secondary: {
      dark: "#b29c3c",
      main: "#ffe057",
      light: "#ffe678",
      extraLight: "#fdf6a7",
      xxLight: "#fffef3",
    },
  },

  gradient: {
    main: "linear-gradient(to left, rgb(0 126 255), rgb(2, 214, 242))",
    mainReverse: "linear-gradient(to left, rgb(2, 214, 242), rgb(0 126 255))",
    dark: "linear-gradient(to left, rgb(0 98 197), rgb(2 175 197))",
    light: "linear-gradient(to left, rgb(152 199 247), rgb(177 244 253))",
    lightReverse: "linear-gradient(to left, rgb(177 244 253), rgb(152 199 247))",
  },

  shape: {
    borderRadius: 7,
  },
  customViewSize: "calc(100vh - 72px)",
});

theme.components = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        margin: 0,
        fontSize: "0.875rem",
        lineHeight: 1.43,
        letterSpacing: "0.01071em",
        background: tokens.palette.neutral[1],
      },
      "*": {
        boxSizing: "border-box",
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        // "& form": { display: "grid", gap: "1em" },
        '& .MuiButton-root[type="submit"]': {
          // fontWeight: 900,
          // color: "white",
          // borderRadius: 0,
          [theme.breakpoints.down(450)]: {
            // position: "fixed",
            // left: "0",
            // bottom: 0,
            // width: "100%",
            // zIndex: 10,
          },
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: { borderRadius: 50 },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        minHeight: "72px",
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        padding: "0px 9px",
      },
      indicator: {
        height: "8px",
        borderRadius: "4px 4px 0 0",
        background: theme.gradient.main,
      },
    },
  },
};

interface GradientBackground {
  dark: string;
  main: string;
  light: string;
  mainReverse?: string;
  lightReverse?: string;
}

declare module "@mui/material/styles" {
  interface Theme {
    customViewSize: string;
    gradient: GradientBackground;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    customViewSize?: string;
    gradient?: GradientBackground;
  }
  interface PaletteColor {
    extraLight?: string;
    darker?: string;
    xxLight?: string;
  }
  interface SimplePaletteColorOptions {
    extraLight?: string;
    darker?: string;
    xxLight?: string;
  }
}
