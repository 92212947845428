import { createTheme } from "@mui/material/styles";
import merge from "lodash/merge";

import { breakpoints } from "./breakpoints";
import type { Colors } from "./colors";
import { alert } from "./components/alert";
import { button } from "./components/button";
import { card } from "./components/card";
import { checkbox } from "./components/checkbox";
import { chip } from "./components/chip";
import { cssBaseline } from "./components/css-baseline";
import { dialog } from "./components/dialog";
import { drawer } from "./components/drawer";
import { form } from "./components/form";
import { menu } from "./components/menu";
import { pagination } from "./components/pagination";
import { paper } from "./components/paper";
import { skeleton } from "./components/skeleton";
import { tabs } from "./components/tabs";
import { tooltip } from "./components/tooltip";
import { typography } from "./components/typography";
import type { PaletteGradient } from "./gradient";
import type { PaletteShadows } from "./shadows";
import { tokens } from "./tokens";

export const theme = createTheme(
  merge(
    cssBaseline,
    breakpoints,
    alert,
    button,
    card,
    checkbox,
    chip,
    dialog,
    drawer,
    form,
    menu,
    pagination,
    paper,
    skeleton,
    tabs,
    tooltip,
    typography,
    tokens,
  ),
);

declare module "@mui/material/styles" {
  interface Palette {
    neutral: PaletteColor;
    gray: PaletteColor;
    teal: PaletteColor;
    blue: PaletteColor;
    green: PaletteColor;
    yellow: PaletteColor;
    red: PaletteColor;
    shadows: PaletteShadows;
    gradient: PaletteGradient;
  }
  interface PaletteOptions {
    neutral: PaletteColor;
    gray: PaletteColor;
    teal: PaletteColor;
    blue: PaletteColor;
    green: PaletteColor;
    yellow: PaletteColor;
    red: PaletteColor;
    shadows: PaletteShadows;
    gradient: PaletteGradient;
  }
  interface PaletteColor extends Colors {}
  interface SimplePaletteColorOptions {
    extraLight?: string;
    darker?: string;
    xxLight?: string;
  }
}
