import ExpandMore from "@mui/icons-material/ExpandMore";
import type { ThemeOptions } from "@mui/material/styles";

import { spacing, tokens } from "../tokens";

export const form: ThemeOptions = {
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: tokens.palette.neutral[7],
          fontSize: 16,
          fontWeight: 500,
          "&.Mui-focused": {
            color: tokens.palette.neutral[7],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        multiline: { padding: 0 },
        root: {
          borderRadius: spacing(0.75),
          fontSize: 16,

          ".MuiFormLabel-root + &": {
            marginTop: spacing(1),
          },
          "&:hover:not(.Mui-disabled)": {
            "&& fieldset": {
              borderColor: tokens.palette.teal[4],
            },
          },
          "&.Mui-disabled": {
            backgroundColor: tokens.palette.neutral[2],
          },
        },
        input: {
          padding: spacing(1.5),
          "::placeholder": {
            opacity: 1,
            color: tokens.palette.neutral[4],
          },
          "&.MuiInputBase-inputAdornedStart": {
            paddingLeft: 0,
          },
        },
        notchedOutline: {
          borderColor: tokens.palette.neutral[3],
          ":hover": {
            borderColor: tokens.palette.error[3],
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingLeft: spacing(2),
          paddingRight: `${spacing(5)}px !important`,
        },
        icon: {
          right: spacing(1.5),
        },
      },
      defaultProps: {
        IconComponent: ExpandMore,
      },
    },
  },
};
