export type PaletteGradient = {
  50: string;
  100: string;
  200: string;
};

export const gradient = {
  50: "radial-gradient(116.04% 233.06% at 107.29% 56.82%, #7EB2FF 17.71%, #6DE5FF 100%)",
  100: "radial-gradient(116.04% 233.06% at 107.29% 56.82%, #3585FE 17.71%, #34DAFF 100%)",
  200: "radial-gradient(116.04% 233.06% at 107.29% 56.82%, #005CE6 17.71%, #00C8F3 100%)",
};
