export interface Colors {
  0: string;
  1: string;
  2: string;
  "2-5"?: string;
  3: string;
  4: string;
  5: string;
  "5-5"?: string;
  6: string;
  7?: string;
}

export type Color = "gray" | "teal" | "blue" | "green" | "yellow" | "red";

export const colors: Record<Color, Colors> = {
  gray: {
    0: "#fff",
    1: "#f9fafc",
    2: "#edeff5",
    "2-5": "#CDD5E2",
    3: "#adbbcf",
    4: "#94a3b8",
    5: "#7f8b9b",
    "5-5": "#656E7B",
    6: "#31343c",
    7: "#0b0f10",
  },
  teal: {
    0: "#f2fafd",
    1: "#d3eef7",
    2: "#a8deef",
    3: "#7ccde6",
    4: "#0cb4e9",
    5: "#107c9e",
    6: "#0f4556",
  },
  blue: {
    0: "#f3f8ff",
    1: "#ebf2ff",
    2: "#d6e4ff",
    3: "#9dc4ff",
    4: "#3f82f0",
    5: "#296acc",
    6: "#1f5099",
  },
  green: {
    0: "#f5fBf8",
    1: "#eef8f4",
    2: "#dcf2ea",
    3: "#a3e6cd",
    4: "#52bd94",
    5: "#429777",
    6: "#317159",
  },
  yellow: {
    0: "#fffaf1",
    1: "#ffefd2",
    2: "#ffdfa6",
    3: "#ffd079",
    4: "#ffb020",
    5: "#996a13",
    6: "#66460d",
  },
  red: {
    0: "#fdf4f4",
    1: "#f9dada",
    2: "#f4b6b6",
    3: "#ee9191",
    4: "#d14343",
    5: "#a73636",
    6: "#7d2828",
  },
};
