import type { ThemeOptions } from "@mui/material/styles";

import { tokens } from "../tokens";
import { typographyVariants } from "./typography";

export const tooltip: ThemeOptions = {
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: tokens.palette.neutral[6],
          ...typographyVariants.b4,
        },
        arrow: {
          color: tokens.palette.neutral[6],
        },
      },
    },
  },
};
