import type { ThemeOptions } from "@mui/material/styles";
import {
  IconAlertCircleFilled,
  IconAlertTriangleFilled,
  IconCircleCheckFilled,
} from "@tabler/icons-react";

import { InfoCircleFilledIcon } from "~/components/icons";

import { tokens } from "../tokens";
import { typographyVariants } from "./typography";

export const alert: ThemeOptions = {
  components: {
    MuiAlert: {
      defaultProps: {
        variant: "outlined",
        severity: "info",
        iconMapping: {
          success: (
            <IconCircleCheckFilled
              style={{
                color: tokens.palette.success[4],
              }}
              size={24}
            />
          ),
          info: (
            <InfoCircleFilledIcon
              style={{
                color: tokens.palette.info[4],
              }}
              width={24}
            />
          ),
          warning: (
            <IconAlertTriangleFilled
              style={{
                color: tokens.palette.warning[4],
              }}
              size={24}
            />
          ),
          error: (
            <IconAlertCircleFilled
              style={{
                color: tokens.palette.error[4],
              }}
              size={24}
            />
          ),
        },
      },
      styleOverrides: {
        root: {
          borderRadius: 8,
          "&.MuiAlert-outlined": {
            borderWidth: 2,
            padding: "4px 12px",
            "&.MuiAlert-message": {
              ...typographyVariants.b4,
              color: tokens.palette.neutral[6],
            },

            "& .MuiAlertTitle-root:last-child": {
              marginBottom: 0,
            },

            "&.MuiAlert-outlinedSuccess": {
              backgroundColor: tokens.palette.success[0],
              "& .MuiAlertTitle-root": {
                color: tokens.palette.success[6],
              },
            },
            "&.MuiAlert-outlinedInfo": {
              backgroundColor: tokens.palette.info[0],
              "& .MuiAlertTitle-root": {
                color: tokens.palette.info[6],
              },
            },
            "&.MuiAlert-outlinedWarning": {
              backgroundColor: tokens.palette.warning[0],
              "& .MuiAlertTitle-root": {
                color: tokens.palette.warning[6],
              },
            },
            "&.MuiAlert-outlinedError": {
              backgroundColor: tokens.palette.error[0],
              "& .MuiAlertTitle-root": {
                color: tokens.palette.error[6],
              },
            },
          },
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          ...typographyVariants.sh4,
        },
      },
    },
  },
};
