export const breakpoints = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768, // Mobile is < 768px
      lg: 1200, // Desktop is >= 1200px,
      xl: 1440,
      xxl: 1920,
    },
  },
};
