import type { ThemeOptions } from "@mui/material/styles";
import type { CSSProperties } from "react";

const BODY_FONT_FAMILY = "Figtree, sans-serif";

interface WtTypographyVariants<T> {
  // Bold Heading
  bh11: T;
  bh10: T;
  bh9: T;
  bh8: T;
  bh7: T;
  bh6: T;
  bh5: T;
  // Heading
  h9: T;
  h8: T;
  h7: T;
  h6: T;
  h5: T;
  h4: T;
  h3: T;
  // Sub Heading
  sh5: T;
  sh4: T;
  sh3: T;
  sh35: T;
  sh2: T;
  // Body
  b7: T;
  b6: T;
  b5: T;
  b4: T;
  b3: T;
}

export const typographyVariants: WtTypographyVariants<CSSProperties> = {
  // Bold Heading
  bh11: { fontSize: 40, fontWeight: 700, lineHeight: "50px", letterSpacing: -0.4 },
  bh10: { fontSize: 32, fontWeight: 700, lineHeight: "40px", letterSpacing: -0.32 },
  bh9: { fontSize: 28, fontWeight: 700, lineHeight: "36px", letterSpacing: -0.28 },
  bh8: { fontSize: 24, fontWeight: 700, lineHeight: "32px", letterSpacing: -0.24 },
  bh7: { fontSize: 20, fontWeight: 700, lineHeight: "28px", letterSpacing: -0.1 },
  bh6: { fontSize: 18, fontWeight: 700, lineHeight: "24px", letterSpacing: -0.09 },
  bh5: { fontSize: 16, fontWeight: 700, lineHeight: "24px" },
  // Heading
  h9: { fontSize: 32, fontWeight: 600, lineHeight: "40px", letterSpacing: -0.32 },
  h8: { fontSize: 24, fontWeight: 600, lineHeight: "32px", letterSpacing: -0.24 },
  h7: { fontSize: 20, fontWeight: 600, lineHeight: "28px", letterSpacing: -0.1 },
  h6: { fontSize: 18, fontWeight: 600, lineHeight: "24px" },
  h5: { fontSize: 16, fontWeight: 600, lineHeight: "24px" },
  h4: { fontSize: 14, fontWeight: 600, lineHeight: "24px" },
  h3: { fontSize: 12, fontWeight: 600, lineHeight: "22px" },
  // Sub Heading
  sh5: { fontSize: 16, fontWeight: 500, lineHeight: "24px" },
  sh4: { fontSize: 14, fontWeight: 500, lineHeight: "24px" },
  sh3: { fontSize: 14, fontWeight: 600, lineHeight: "22px", letterSpacing: 1.4 },
  sh35: { fontSize: 12, fontWeight: 600, lineHeight: "20px", letterSpacing: 1.2 },
  sh2: { fontSize: 12, fontWeight: 500, lineHeight: "24px" },
  // Body
  b7: { fontSize: 20, fontWeight: 400, lineHeight: "28px" },
  b6: { fontSize: 18, fontWeight: 400, lineHeight: "24px" },
  b5: { fontSize: 16, fontWeight: 400, lineHeight: "24px" },
  b4: { fontSize: 14, fontWeight: 400, lineHeight: "22px" },
  b3: { fontSize: 12, fontWeight: 400, lineHeight: "20px" },
};

export const typography: ThemeOptions = {
  typography: {
    fontFamily: BODY_FONT_FAMILY,

    ...typographyVariants,
  } as ThemeOptions["typography"],

  components: {
    MuiTypography: {
      defaultProps: { variant: "b4" },
      styleOverrides: {
        root: {
          fontFamily: BODY_FONT_FAMILY,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: BODY_FONT_FAMILY,
        },
      },
    },
  },
};

declare module "@mui/material/styles" {
  interface TypographyVariants extends WtTypographyVariants<CSSProperties> {}

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions extends WtTypographyVariants<CSSProperties> {}
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides extends WtTypographyVariants<true> {}
}
