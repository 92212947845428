import type { PaletteColor } from "@mui/material";
import { createTheme } from "@mui/material";

import type { Colors } from "./colors";
import { colors } from "./colors";
import { gradient } from "./gradient";
import { shadows } from "./shadows";

export const theme = createTheme();

function makeColor(colors: Colors): PaletteColor {
  return {
    ...colors,
    ...theme.palette.augmentColor({ color: { main: colors["4"] } }),
  };
}

export const tokens = {
  palette: {
    gray: makeColor(colors.gray),
    teal: makeColor(colors.teal),
    blue: makeColor(colors.blue),
    red: makeColor(colors.red),
    yellow: makeColor(colors.yellow),
    green: makeColor(colors.green),
    neutral: makeColor(colors.gray),
    primary: makeColor(colors.teal),
    info: makeColor(colors.blue),
    success: makeColor(colors.green),
    error: makeColor(colors.red),
    warning: makeColor(colors.yellow),

    text: {
      primary: colors.gray[7],
    },
    shadows,
    gradient,
  },
};

export function spacing(value: number) {
  return value * 8;
}
