import type { ThemeOptions } from "@mui/material/styles";

import { tokens } from "../tokens";
import { typographyVariants } from "./typography";

export const button: ThemeOptions = {
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableFocusRipple: true,
        disableRipple: true,
        disableTouchRipple: true,
      },
      variants: [
        {
          props: { variant: "gradient" },
          style: {
            background: tokens.palette.gradient[100],
            color: tokens.palette.neutral[0],
            transition: "background 0.2s ease-in-out",
            "&:active": {
              background: tokens.palette.gradient[200],
              outline: `3px solid ${tokens.palette.primary[6]}`,
            },
            // Only apply hover styles on devices that support hover, avoid keep hover styles on touch devices
            "@media (hover: hover)": {
              "&:hover": {
                background: tokens.palette.gradient[200],
              },
            },
            "&:focus-visible": {
              background: tokens.palette.gradient[200],
              outline: `3px solid ${tokens.palette.primary[3]}`,
            },
            "&.Mui-disabled": {
              background: tokens.palette.gradient[50],
              color: tokens.palette.neutral[1],
            },
          },
        },
        {
          props: { size: "xLarge" },
          style: {
            height: "56px",
            padding: "16px 44px",
            ...typographyVariants.h6,
            "&.MuiButton-outlined": {
              padding: "14px 42px",
            },
          },
        },
        {
          props: { color: "gray" },
          style: {
            color: tokens.palette.neutral[6],
            backgroundColor: tokens.palette.neutral[2],
            "&:hover": {
              backgroundColor: tokens.palette.neutral[3],
            },
            "@media (hover: none)": {
              "&:hover": {
                backgroundColor: tokens.palette.neutral[2],
              },
            },
            "&:active": {
              backgroundColor: tokens.palette.neutral[2],
              outline: `3px solid ${tokens.palette.neutral[5]}`,
            },
            "&:focus-visible": {
              outline: `3px solid ${tokens.palette.neutral[3]}`,
            },
            "&.Mui-disabled": {
              color: tokens.palette.neutral[4],
              backgroundColor: tokens.palette.neutral[1],
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: "9999px",
          textTransform: "unset",
        },
        containedPrimary: {
          color: tokens.palette.neutral[0],
          "&:active": {
            backgroundColor: tokens.palette.primary[5],
            outline: `3px solid ${tokens.palette.primary[6]}`,
          },
          ":focus-visible": {
            outline: `3px solid ${tokens.palette.primary[3]}`,
          },
          "&.Mui-disabled": {
            backgroundColor: tokens.palette.primary[1],
            color: "white",
          },
        },
        containedError: {
          "&:active": {
            outline: `3px solid ${tokens.palette.error[6]}`,
          },
          "&:focus-visible": {
            outline: `3px solid ${tokens.palette.error[3]}`,
          },
          "&.Mui-disabled": {
            color: "white",
            backgroundColor: tokens.palette.error[1],
          },
        },
        text: {
          "&:hover, &:active, &:focus-visible": {
            textDecoration: "underline",
            backgroundColor: "transparent",
          },
          "&:active": {
            color: tokens.palette.primary[6],
          },
          "&.Mui-disabled": {
            color: tokens.palette.primary[1],
          },
        },
        outlined: {
          borderWidth: "2px",
          "&:hover": {
            borderWidth: "2px",
          },
        },
        outlinedPrimary: {
          borderColor: tokens.palette.primary[4],
          "&:active": {
            borderColor: tokens.palette.primary[5],
          },
          "&:focus-visible": {
            outline: `1px solid ${tokens.palette.primary[3]}`,
            borderColor: tokens.palette.primary[3],
          },
          "&.Mui-disabled": {
            color: tokens.palette.primary[1],
            borderColor: tokens.palette.primary[1],
          },
        },
        outlinedSizeSmall: {
          padding: "5px 11px",
          borderWidth: "1px",
          "&:hover": {
            borderWidth: "1px",
          },
          "&.Mui-disabled": {
            borderWidth: "1px",
          },
        },
        outlinedSizeMedium: {
          padding: "6px 18px",
        },
        outlinedSizeLarge: {
          padding: "8px 26px",
        },
        sizeSmall: {
          height: "36px",
          padding: "6px 12px",
          ...typographyVariants.h4,
        },
        sizeMedium: {
          height: "40px",
          padding: "8px 20px",
          ...typographyVariants.h5,
        },
        sizeLarge: {
          height: "44px",
          padding: "10px 28px",
          ...typographyVariants.h5,
        },
        endIcon: {
          marginLeft: "4px",
        },
        startIcon: {
          marginRight: "4px",
        },
      },
    },
  },
};

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    gradient: true;
  }
  interface ButtonPropsColorOverrides {
    gray: true;
  }
  interface ButtonPropsSizeOverrides {
    xLarge: true;
  }
}
