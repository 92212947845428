import type { ThemeOptions } from "@mui/material/styles";

import { theme, tokens } from "../tokens";
import { typographyVariants } from "./typography";

export const tabs: ThemeOptions = {
  components: {
    MuiTabs: {
      styleOverrides: {
        root: { minHeight: "auto" },
        flexContainer: {
          gap: theme.spacing(1),
          marginBottom: theme.spacing(0.25),
        },
        indicator: {
          height: 3,
          backgroundColor: tokens.palette.primary[4],
          borderRadius: "9999px",
        },
      },
    },

    MuiTab: {
      defaultProps: { disableRipple: true },
      styleOverrides: {
        root: {
          // reset
          textTransform: "none",
          minHeight: "auto",
          minWidth: "auto",
          // css
          ...typographyVariants["b4"],
          color: tokens.palette.neutral[5],
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          paddingTop: theme.spacing(0.4),
          paddingBottom: theme.spacing(0.4),
          marginBottom: theme.spacing(0.25),
          borderRadius: 6,
          border: "2px solid transparent",
          "&:hover": {
            color: tokens.palette.neutral[7],
          },
          "&.Mui-selected": {
            color: tokens.palette.neutral[7],
            ...typographyVariants["h4"],
          },
          "&.Mui-focusVisible": {
            borderColor: tokens.palette.primary[2],
          },
          // Tab sizes, default is small
          "&.MuiTab-medium": {
            ...typographyVariants.b5,
            "&.Mui-selected": {
              ...typographyVariants.h5,
            },
          },
          "&.MuiTab-large": {
            ...typographyVariants.b6,
            "&.Mui-selected": {
              ...typographyVariants.h6,
            },
          },
        },
      },
    },
  },
};
